<template>
  <div id="idea-plan">
    <div class="container-1200">
      <div class="content">
        <div class="content-overlay" />
        <header class="page-header">

          <!-- Dashboard Header -->
          <dashboard-header
            :title="$t('sidebar.ideaPlan')"
            :quick-tour-visible="quickTourVisible"
            hide-guide-button
            :flex-column="false"
            @toggle-quick-tour="onQuickTourVisible"
          >
            <template #actions>

              <div class="d-flex">
                <div class="export-share-button d-flex justify-content-center align-items-center">
                  <a v-if="subscriptionIsFreeTrial" class="share d-flex align-items-center" @click="showUpgradeDialog">
                    <i class="ib-icon-download h3 text-silver" />
                    <span class="d-none d-lg-flex ml-2 text-silver font-outfit-regular">{{ $t('export') }}</span>
                  </a>
                  <el-dropdown v-if="!subscriptionIsFreeTrial" class="action-items" trigger="click"
                               @command="generateDocument"
                  >
                    <a class="d-flex align-items-center download el-dropdown-link print" @click.prevent>
                      <i class="ib-icon-download h3 text-silver hover-scale" />
                      <span class="d-none d-lg-flex ml-2 text-silver font-outfit-regular">{{ $t('export') }}</span>
                    </a>
                    <el-dropdown-menu slot="dropdown" class="export-dropdown-menu">
                      <el-dropdown-item class="d-flex" icon="ib-icon-export-pdf" command="pdf">
                        {{ $t('exportToPdf') }}
                      </el-dropdown-item>
                      <el-dropdown-item class="d-flex" icon="ib-icon-export-word" command="doc">
                        {{ $t('exportToWord') }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>

                <div class="export-share-button d-flex justify-content-center align-items-center ml-3">
                  <a class="share d-flex align-items-center" @click="sharePlan">
                    <i v-if="shareLoading" class="el-icon-loading" />
                    <i v-if="!shareLoading" class="ib-icon-share h3 text-silver" />
                    <span class="d-none d-lg-flex ml-2 text-silver font-outfit-regular">{{ $t('share') }}</span>
                  </a>
                </div>
              </div>

            </template>
          </dashboard-header>
          <!-- /Dashboard Header -->
          <hr>
        </header>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Quick tour -->
        <quick-tour-card-wrapper
          :active="quickTourVisible"
          closable
          @close="onCloseQuickTour"
        >
          <idea-plan-quick-tour />
        </quick-tour-card-wrapper>
        <!-- /Quick tour -->

        <!-- Loader -->
        <loader v-if="initialLoading" />
        <!-- /Loader -->

        <template v-else>
          <!-- Idea Plan Header -->
          <business-guide-header
            v-if="isPageContentVisible"
            class="mb-1"
            flex-grow
          >
            <template #left>
              <!-- Title -->
              <editable-title
                v-model="ideaPlanTitle"
                class="m-0"
                :disabled="!canEdit"
                @save="saveIdeaPlanTitle"
              />
            </template>
            <template #right>
              <template v-if="canEdit && hasItemsFromTemplate">
                <!-- Delete template note handwriting -->
                <delete-template-note-handwriting v-if="hasItemsFromTemplate && isIdeaFromTemplate && !ideaPlan.isTouched" />
                <!-- /Delete template note handwriting -->
                <!-- Action Buttons -->
                <div class="action-buttons d-flex align-items-center">
                  <erase-icon-button
                    class="ml-4"
                    :disabled="disableRemoveButton"
                    @click="dialogRemoveExamplesVisible = true"
                  />
                </div>
                <!-- /Action Buttons -->
              </template>
            </template>
          </business-guide-header>
          <!-- /Idea Plan Header -->

          <template v-if="isPageContentVisible">
            <component :is="layout" :data="ideaPlan.sections" @refresh-layout="refreshLayout" />
          </template>

        </template>

        <!-- Add More Details -->
        <add-more-details
          v-if="isPageContentVisible"
          :concept-name="'ideaPlanMoreDetails'"
          :step="'idea-plan'"
        />
        <!-- /Add More Details -->

        <dialog-delete-plan />

        <bottom-back-block />
      </div>
    </div>

    <!-- Dialog Share Plan -->
    <dialog-share
      :title="$t('dialogs.share.shareIdeaPlan')"
      type="ideaPlan"
      :visible="dialogSharePlanVisible"
      :token="sharedPlanToken"
      @close="onCloseSharePlan"
    />
    <!-- /Dialog Share Plan -->

    <!-- Dialog Export/Generate -->
    <dialog-generate
      :api="'/idea-plan/generate/' + ideaPlan.id"
      :format="generateFormat"
      :dialog-visible="generateDialog"
      @close="generateDialog = false"
    />
    <!-- /Dialog Export/Generate -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->
  </div>
</template>
<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import BottomBackBlock from '@/components/_v2/Common/BottomBackBlock'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DeleteTemplateNoteHandwriting from '@/views/Home/Components/DeleteTemplateNoteHandwriting'
import DialogDeletePlan from './Dialogs/DialogDeletePlan'
import DialogGenerate from '@/views/Home/StoryMode/Components/Dialogs/DialogGenerate'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import DialogShare from '@/views/Home/Components/Dialogs/DialogShare'
import EditableTitle from '@/components/_v2/EditableTitle'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import Example from '@/views/Home/StoryMode/Components/Example/GuideExample'
import IdeaPlanLayout from '@/views/Home/IdeaPlan/Section/IdeaPlanLayout'
import IdeaPlanQuickTour from '@/views/Home/Components/QuickTours/IdeaPlanQuickTour'
import Item from '@/views/Home/IdeaPlan/Section/Item'
import Loader from '@/components/Loader'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import QuickTourCardWrapper from '@/views/Home/Components/QuickTours/QuickTourCardWrapper'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'IdeaPlan',

  components: {
    AddMoreDetails,
    BottomBackBlock,
    BusinessGuideHeader,
    DashboardHeader,
    DeleteTemplateNoteHandwriting,
    DialogDeletePlan,
    DialogGenerate,
    DialogRemoveExamples,
    DialogShare,
    EditableTitle,
    EraseIconButton,
    Example,
    IdeaPlanLayout,
    IdeaPlanQuickTour,
    Item,
    Loader,
    QuickTourCardWrapper
  },

  mixins: [
    MixinEraseTemplate,
    MixinGuide,
    MixinIdeaRoles,
    MixinUserSubscription
  ],

  data () {
    return {
      ideaPlanTitle: null,
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      quickTourVisible: false,
      dialogDeletePlanVisible: false,
      dialogSharePlanVisible: false,
      shareLoading: false,
      sharedPlanToken: '',
      examples: [],
      layout: 'IdeaPlanLayout',
      initialLoading: false,
      editField: '',
      btnSave: false,
      max: 50,
      generateDialog: false,
      generateFormat: '',
      isPageContentVisible: true
    }
  },

  computed: {
    ...mapGetters('idea/ideaPlan', [
      'ideaPlan',
      'countIdeaPlanSections',
      'notes'
    ]),

    ...mapGetters('idea', ['isIdeaFromTemplate']),

    isTitleEnabled () {
      return this.ideaPlan.title.trim() && this.ideaPlan.title.length > 1
    },

    disableRemoveButton () {
      return !this.hasItemsFromTemplate || !this.canEdit
    },

    hasItemsFromTemplate () {
      if (this.notes.length) {
        return this.notes.some(note => !note.isTouched && note.isFromTemplate)
      }

      return false
    }
  },

  watch: {
    'ideaPlan.title': {
      handler (value) {
        this.ideaPlanTitle = value
      },
      immediate: true
    }
  },

  created () {
    this.openQuickTour('ideaPlanQuickTour')
  },

  mounted () {
    if (this.$store.state.idea.ideaPlan.ideaPlans && this.$store.state.idea.ideaPlan.ideaPlans.length) {
      this.setData(this.$store.state.idea.ideaPlan.ideaPlans[0])
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteIdeaExamples'
    ]),
    ...mapActions('idea/ideaPlan', [
      'getIdeaPlan'
    ]),
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    onCloseQuickTour () {
      this.quickTourVisible = false
      this.isPageContentVisible = true
    },

    showUpgradeDialog () {
      this.setDialogUpgradeState(true)
    },

    setData (ideaPlan) {
      if (this.countIdeaPlanSections === 0) {
        this.initialLoading = true
      }
      this.getIdeaPlan(ideaPlan.id)
        .then((response) => {
          if (response.status === 200) {
            this.intercomUpdateUser(response.data.payload.ideaPlan.sections.reduce((a, item) => a + item.notes.length, 0))
          }
        }).finally(() => {
          this.initialLoading = false
        })
    },

    saveIdeaPlanTitle () {
      this.btnSave = false
      this.$http.put(
          `idea-plan/title/${+this.ideaPlan.id}`,
          {
            id: this.$store.state.idea.id,
            title: this.ideaPlanTitle
          }
      ).then((response) => {
        if (response.status === 201) {
          this.$store.commit('idea/updateIdeaPlanTitle', response.data.payload.ideaPlan)
        }
      })
    },

    showDeletePlan () {},

    generateDocument (format) {
      this.generateFormat = format
      this.generateDialog = true
    },

    refreshLayout () {
      this.setData(this.ideaPlan)
    },

    async sharePlan () {
      if (this.subscriptionIsFreeTrial) {
        this.showUpgradeDialog()

        return
      }

      this.shareLoading = true

      await this.getSharedPlanToken()
      this.dialogSharePlanVisible = true

      this.shareLoading = false
    },

    async getSharedPlanToken () {
      if (!this.sharedPlanToken) {
        const response = await this.$http.post('idea-plan/share', { ideaPlanId: this.ideaPlan.id })
        this.sharedPlanToken = response.data.payload.sharedPlan.token
      }

      return this.sharedPlanToken
    },

    onCloseSharePlan () {
      this.dialogSharePlanVisible = false
    },

    onQuickTourVisible (event) {
      this.quickTourVisible = event
      this.isPageContentVisible = true

      this.$gtm.trackEvent({
        event: 'playIntro'
      })
    },

    intercomUpdateUser (count) {
      this.$intercom.update({
        IdeaPlanCount: count
      })
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('idea-plan')
        .then(_ => {
          this.refreshLayout()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
#idea-plan {
  margin-left: 0;
}

.export-share-button {
  max-width: 118px;
  border-radius: 8px;
  width: 37px;
  height: 34px;
  background: transparent;
  cursor: pointer;
  transition: all ease-in-out .25s;

  &:hover {
    background: rgba(200, 198, 196, 0.20);
  }

  @include media-breakpoint-up($lg) {
    width: auto;
    height: 50px;
    padding: 0 10px;
  }

  a {
    text-decoration: none;
    font-size: 17px;
  }
}
</style>
